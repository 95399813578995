import { application } from "~/controllers/application";
import { registerControllers } from "stimulus-vite-helpers";

const controllers = import.meta.globEager([
  "~/controllers/dropdown_controller.js",
  "~/controllers/select_dropdown_controller.js",
  "~/controllers/content_switcher_controller.js",
  "~/controllers/popover_controller.js",
  "~/controllers/pager_controller.js",
  "~/controllers/disable_button_on_turbo_submit_controller.js",
  "~/controllers/ga_event_controller.js",
  "~/controllers/cookie_consent_controller.js",
  "~/controllers/modal_controller.js",
  "~/controllers/modal_close_controller.js",
  "~/controllers/display_toaster_controller.js",
  "~/controllers/mount_react_components_controller.js",
  "~/controllers/adaptive_carousel_controller.js",
]);

registerControllers(application, controllers);
